<template>
    <el-date-picker :clearable="clearable" @change="update" type="daterange" v-model="date_range"
                    :size="size" :disabled="disabled" :editable="false" :shortcuts="shortcuts"
                    :disabled-date="innerDisabledDate" ref="picker"
                    :start-placeholder="startPlaceholder" :end-placeholder="endPlaceholder"></el-date-picker>
</template>

<script>
import {timestampToDate} from "../libs/utils";

export default {
    name: "DateRangePicker",
    props: {
        modelValue: Object,
        size: String,
        disabled: Boolean,
        disabledDate: Function,
        clearable: Boolean,
        startPlaceholder: String,
        endPlaceholder: String,
        valueStartName: {
            type: String,
            default: 'start'
        },
        valueEndName: {
            type: String,
            default: 'end'
        }
    },
    data() {
        return {
            date_range: [], shortcuts: [{
                text: '最近7天',
                value: (_ => {
                    const now = Date.now();
                    return [
                        now - 8 * 24 * 3600 * 1000,
                        now - (this.$root.t || 2) * 24 * 3600 * 1000
                    ]
                })()
            }, {
                text: '最近30天',
                value: (_ => {
                    const now = Date.now();
                    return [
                        now - 31 * 24 * 3600 * 1000,
                        now - (this.$root.t || 2) * 24 * 3600 * 1000
                    ]
                })()
            }, {
                text: '上个月',
                value: (_ => {
                    const now = new Date();
                    let year = now.getFullYear();
                    let month = now.getMonth();
                    const end = new Date(`${year}-${month + 1}-01`) - 24 * 3600 * 1000;
                    if (!month) {
                        month = 12;
                        year--;
                    }
                    const start = new Date(`${year}-${month}-01`)
                    return [start, end]
                })()
            }], inner_update: false,
        }
    },
    methods: {
        focus() {
            this.$refs.picker.focus();
        },
        init() {
            this.date_range = [
                this.modelValue[this.valueStartName] ? new Date(this.modelValue[this.valueStartName]) : null,
                this.modelValue[this.valueEndName] ? new Date(this.modelValue[this.valueEndName]) : null,
            ];
        },
        update() {
            this.inner_update = true;
            this.modelValue[this.valueStartName] = this.date_range ? timestampToDate(this.date_range[0]) : null;
            this.modelValue[this.valueEndName] = this.date_range ? timestampToDate(this.date_range[1]) : null;
            // this.$emit('change');
            this.$nextTick(_ => {
                this.inner_update = false;
            })
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "modelValue.start"() {
            if (!this.inner_update)
                this.init();
        },
        "modelValue.end"() {
            if (!this.inner_update)
                this.init();
        },
    },
    computed: {
        innerDisabledDate() {
            if (this.disabledDate) {
                return this.disabledDate
            } else
                return time => {
                    return time.getTime() + 8 * 3600 * 1000 > Date.now() || time.getTime() < 1630425600000;
                }
        }
    }
}
</script>

<style scoped>

</style>
