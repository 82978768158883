<template>
    <div>
        <el-button type="primary" :loading="loading" @click="openDialog()">新建关卡</el-button>
    </div>
    <el-table v-loading="loading" :data="data" style="margin-top: 10px">
        <el-table-column label="关卡" width="100">
            <template #default="scope">
                {{ data.length - scope.$index }}
            </template>
        </el-table-column>
        <el-table-column label="活跃" prop="active" width="150">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                           @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="目标图" prop="target" width="150">
            <template #default="scope">
                <el-image :src="`${$root.cdn_url}/${scope.row.target}`" lazy style="width: 100px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="干扰图" prop="chaos" width="150">
            <template #default="scope">
                <el-image :src="`${$root.cdn_url}/${scope.row.chaos}`" lazy style="width: 100px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="宽" prop="width" width="100"></el-table-column>
        <el-table-column label="高" prop="height" width="100"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" label-width="100px" :model="form">
            <el-form-item label="目标图" prop="target" :rules="[{required: true, message: '请上传目标图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('target')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.target" :src="`${$root.cdn_url}/${form.target}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="干扰图" prop="chaos" :rules="[{required: true, message: '请上传干扰图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('chaos')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.chaos" :src="`${$root.cdn_url}/${form.chaos}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="宽" prop="width" :rules="[{required: true, message: '请输入宽'}]">
                <el-input-number v-model="form.width" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="高" prop="height" :rules="[{required: true, message: '请输入高'}]">
                <el-input-number v-model="form.height" :disabled="loading"></el-input-number>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" @click="reset" text :loading="loading">取消</el-button>
            <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../libs/utils";

export default {
    name: "Difference",
    data() {
        return {
            loading: false, dialog_opened: false, data: [], editing: null,
            form: {
                target: null,
                chaos: null,
                width: null,
                height: null,
                active: false,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/cms/v1/difference').then(res => {
                this.data = res.data.data.differenceList;
                this.loading = false;
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.sort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.sort();
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.unshift(d.id);
            })
            axios.post(`/cms/v1/sort/difference`, {sort}).then(_ => {
            })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/difference/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/difference`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/difference/${row.id}`, row).then(_ => {
                this.loading = false;
            })
        },
        handleSuccess(name) {
            return res => {
                this.form[name] = res.data.name;
                this.loading = false;
            }
        },
        handleProgress() {
            this.loading = true;
        },
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>

</style>